import { acceptHMRUpdate, defineStore } from 'pinia'
import type { ICard, ICardRequestParams } from '~/types/card.types'
import type { ICardRoundUpRule } from '~/types/cardRoundUpRule.types'
import { HTTP_METHOD } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'

const BATCH_SIZE = 25

export const useCardsStore = defineStore('cards', () => {
  const oliveBaseUrl = '/cards'

  // #region state
  const cards = ref<ICard[]>([])
  const cardIdsCurrentPage = ref<string[]>([])
  const numberOfCards = ref<number | undefined>()
  const cardRoundUpRules = ref<ICardRoundUpRule[]>([])
  const error = ref<IOliveError>(getOliveError())
  const deactivateError = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const isLoadingForRoundUps = ref(false)
  const filter = ref<ICardRequestParams>({})

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getCardById = computed(() => {
    return (id: string) => cards.value.find(c => c.id === id)
  })

  const getCardsCurrentPage = computed (() => {
    return cards.value.filter(c => cardIdsCurrentPage.value.includes(c.id))
  })

  const getCardRoundUpRulesByCardId = computed(() => {
    return (cardId: string, clientId?: string) => cardRoundUpRules.value.filter(r => r.cardId === cardId && r.roundUpRule?.clientId === clientId)
  })
  // #endregion

  // #region card actions
  const loadCards = async (params?: ICardRequestParams) => {
    const {
      response,
      error: cardFormError,
      run: loadCards,
    } = useOliveAPI<IPlatformResponse<ICard>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading cards',
    })
    isLoading.value = true
    error.value = getOliveError()

    if (params && params.expiryMonth !== undefined)
      params.expiryMonth++ // Backend is a 1 indexed month system, and js starts from 0

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadCards()

    if (response.value?.items && !cardFormError.value.hasError) {
      cards.value = useArrayUnique([...response.value.items, ...cards.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        cardIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfCards.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = cardFormError.value }

    isLoading.value = false
  }

  const loadCardsByIds = async (ids: string[]) => {
    const cardsIdsFiltered = [...new Set(ids.filter(s => !getCardById.value(s)).map(t => t as string).filter(s => !(s === null || s === undefined)))]

    if (cardsIdsFiltered.length === 0)
      return

    const batches = []
    do {
      batches.push(loadCards({ pageSize: cardsIdsFiltered.slice(0, BATCH_SIZE).length, cardId: cardsIdsFiltered.slice(0, BATCH_SIZE) }))
      cardsIdsFiltered.splice(0, BATCH_SIZE)
    }
    while (cardsIdsFiltered.length > 0)
    await Promise.all(batches)
  }

  const loadCard = async (id: string, clientId: string) => {
    if (getCardById.value(id))
      return

    await loadCards({ cardId: [id], clientId })

    if (!getCardById.value(id))
      error.value = getOliveError(true, undefined, { title: 'Card not found' })
  }

  const updateCard = async (card: ICard) => {
    const {
      response,
      error: cardError,
      run: updateCard,
    } = useOliveAPI<ICard>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${card.id}`,
      data: card,
      successMessage: 'Card changes have been saved',
      errorMessage: 'Failed to update card',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateCard()

    if (response.value && !cardError.value.hasError) {
      cards.value = useArrayUnique([response.value, ...cards.value], (a, b) => a.id === b.id).value
    }
    else {
      error.value = cardError.value
      throw new Error(error.value.message.text)
    }

    isLoading.value = false
  }

  const deactivateCard = async (cardId: string, clientId: string, memberId: string) => {
    const {
      error: deactivateCardError,
      run: deactivateCard,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${cardId}/deactivate?memberId=${memberId}`,
      errorMessage: 'Error deactivating card',
    })
    isLoading.value = true
    deactivateError.value = getOliveError()

    await deactivateCard()

    if (!deactivateCardError.value.hasError) {
      cards.value = cards.value.filter(c => c.id !== cardId)
      await loadCard(cardId, clientId)
    }
    else {
      deactivateError.value = deactivateCardError.value
    }

    isLoading.value = false
  }

  const clearCurrentPage = () => {
    cardIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }
  // #endregion

  // #region card round up rules actions
  const loadCardRoundUpRules = async (cardId: string) => {
    if (getCardRoundUpRulesByCardId.value(cardId).length > 0)
      return

    const {
      response,
      error: cardRoundUpRuleError,
      run: loadCardRoundUpRules,
    } = useOliveAPI<ICardRoundUpRule[]>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${cardId}/card_round_up_rules`,
      errorMessage: 'Error loading card round up rules',
    })
    isLoadingForRoundUps.value = true
    error.value = getOliveError()

    await loadCardRoundUpRules()

    if (response.value && !cardRoundUpRuleError.value.hasError)
      cardRoundUpRules.value = useArrayUnique([...response.value, ...cardRoundUpRules.value], (a, b) => a.cardId === b.cardId && a.roundUpRuleId === b.roundUpRuleId).value
    else
      error.value = cardRoundUpRuleError.value

    isLoadingForRoundUps.value = false
  }

  const addCardRoundUpRule = async (cardRoundUpRule: ICardRoundUpRule) => {
    const {
      error: cardRoundUpRuleError,
      run: addCardRoundUpRule,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${cardRoundUpRule.cardId}/card_round_up_rules`,
      data: cardRoundUpRule,
      successMessage: 'Successfully added round up rule to card',
      errorMessage: 'Error adding round up rule',
    })
    isLoadingForRoundUps.value = true
    error.value = getOliveError()

    await addCardRoundUpRule()

    if (cardRoundUpRuleError.value.hasError)
      error.value = cardRoundUpRuleError.value

    isLoadingForRoundUps.value = false
  }

  const modifyCardRoundUpRules = async (cardId: string, crur: ICardRoundUpRule[]) => {
    const {
      response,
      error: cardRoundUpRuleError,
      run: modifyAssetBuildingProgramRoundUpRules,
    } = useOliveAPI<ICardRoundUpRule[]>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${cardId}/card_round_up_rules`,
      data: crur,
      successMessage: 'Successfully changed priority',
      errorMessage: 'Failed to change priority',
    })
    isLoadingForRoundUps.value = true
    error.value = getOliveError()

    await modifyAssetBuildingProgramRoundUpRules()

    if (response.value && !cardRoundUpRuleError.value.hasError)
      cardRoundUpRules.value = useArrayUnique([...cardRoundUpRules.value, ...response.value], (a, b) => a.cardId === b.cardId && a.roundUpRuleId === b.roundUpRuleId).value
    else
      error.value = cardRoundUpRuleError.value

    isLoadingForRoundUps.value = false
  }

  const deleteCardRoundUpRule = async (cardId: string, roundUpRuleId: string) => {
    const {
      error: cardRoundUpRuleError,
      run: deleteCardRoundUpRule,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${cardId}/card_round_up_rules/${roundUpRuleId}`,
      successMessage: 'Successfully removed round up rule from card',
      errorMessage: 'Failed to remove round up rule from card',
    })
    isLoadingForRoundUps.value = true
    error.value = getOliveError()

    await deleteCardRoundUpRule()

    if (!cardRoundUpRuleError.value.hasError) {
      const crr = getCardRoundUpRulesByCardId.value(cardId, roundUpRuleId)
      if (crr) {
        // TODO: change filter logic to make more sense even though concatenating the ID's work
        cardRoundUpRules.value = cardRoundUpRules.value.filter(l => (l.cardId + l.roundUpRuleId !== cardId + roundUpRuleId))
      }
    }
    else { error.value = cardRoundUpRuleError.value }

    isLoadingForRoundUps.value = false
  }
  // #endregion

  return {
    cards,
    numberOfCards,
    cardRoundUpRules,
    getCardById,
    getCardsCurrentPage,
    getCardRoundUpRulesByCardId,
    addCardRoundUpRule,
    modifyCardRoundUpRules,
    deleteCardRoundUpRule,
    isLoading,
    isLoadingForRoundUps,
    filter,
    error,
    deactivateError,
    loadCards,
    loadCardsByIds,
    loadCard,
    loadCardRoundUpRules,
    deactivateCard,
    updateCard,
    clearCurrentPage,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCardsStore as any, import.meta.hot))
